import { Injectable } from "@angular/core";

@Injectable({providedIn: "root"})
export class FrontendEnvironmentService {

    isProductionEnvironment(): boolean {
        return ["pilotweb.fi", "www.pilotweb.fi", "vara.pilotweb.fi"].includes(window.location.hostname);
    }

    isFinnpilotQaEnvironment(): boolean {
        return window.location.hostname === 'testi.pilotweb.fi';
    }
}
